.chart-container {
  background: #f9f9f9;
  width: 100%;
  padding: 0 20%; /* Reduced padding for better alignment */
  text-align: center;
  animation: slideIn 0.5s ease-in-out;
  overflow-x: hidden; /* Adjusted from 'inherit' */
  padding-bottom: 60px;
}

.chart-header {
  font-family: 'Manrope', sans-serif;
  color: rgb(129, 57, 57);
  margin-bottom: 20px; /* Increased margin for better spacing */
  font-size: 2.5rem;
}

.chart-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Increased gap for better spacing */
  margin-bottom: 20px;
}

.chart-description {
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 1.2em;
  color: #070707;
  font-weight: 600;
}

.data-source {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px; /* Increased margin for better spacing */
  font-style: italic;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-container label {
  margin: 5px; /* Uniform margin for consistency */
  font-family: 'Manrope', sans-serif;
}

.checkbox-container input {
  margin: 0 10px; /* Removed left margin and adjusted spacing */
  cursor: pointer;
}

.custom-legend {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
  gap: 20px; /* Added gap for consistent spacing */
}

.custom-legend li {
  display: flex;
  align-items: center;
}

.tab-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  color: rgb(129, 57, 57);
  background-color: white;
  border: 2px solid rgb(129, 57, 57);
  border-radius: 5px 5px 0 0;
  transition: all 0.3s ease;
}

.tab:hover {
  background-color: rgb(129, 57, 57);
  color: white;
}

.tab.active {
  background-color: rgb(129, 57, 57);
  color: white;
}

.button-toggle {
  padding: 10px 20px;
  margin-left: 20px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  color: rgb(129, 57, 57);
  background-color: white;
  border: 2px solid rgb(129, 57, 57);
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
  display: inline-block;
  margin-bottom: 40px;
}


.button-toggle.active {
  background-color: rgb(129, 57, 57);
  color: white;
}

.button-toggle.disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  border-color: #a0a0a0;
}


.date-filter-form {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Added gap for spacing */
}

.date-filter-form label {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
}

.date-filter-form input[type='date'] {
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  border: 1px solid rgb(129, 57, 57);
}

.date-filter-form button {
  padding: 8px 16px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  cursor: pointer;
  background-color: rgb(129, 57, 57);
  color: white;
  border: none;
}

.date-filter-form button:hover {
  background-color: #9b4242;
}

.info-icon-container {
  position: relative;
  display: inline-block;
  margin-right: 10px; /* Reduced margin for better alignment */
  margin-top: 3px;
}

.info-tooltip {
  visibility: hidden;
  width: 200px;
  background-color: rgb(129, 57, 57);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position tooltip below the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: 'Manrope', sans-serif; 
}

.info-icon-container:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

.info-tooltip::after {
  content: ' ';
  position: absolute;
  top: -10px; /* Arrow pointing up */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(129, 57, 57) transparent transparent transparent;
}

.custom-legend svg {
  margin-right: 8px;
}

.toggleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
  margin-right: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #9b4242;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggleLabel {
  font-family: 'Manrope', sans-serif;
  font-size: 1.0em;
  color: #060000;
  font-weight: 600;
}

.label {
  font-family: 'Manrope', sans-serif;
  font-size: 1.0em;
  color: #060000;
  font-weight: 600;
}

/* Mobile view for 400px to 500px */
@media (min-width: 400px) and (max-width: 500px) {
  .chart-container {
    padding: 0 10%; /* Adjusted padding for smaller screens */
    padding-bottom: 50px;
  }

  .chart-header {
    font-size: 1.8rem;
  }

  .chart-description-container {
    margin-left: 20px;
  }

  .chart-description {
    font-size: 1.1em;
  }

  .data-source {
    font-size: 12px;
  }

  .checkbox-container {
    flex-direction: column;
  }

  .checkbox-container label {
    margin: 0 0 8px; /* Adjusted margin for better alignment */
  }

  .custom-legend li {
    margin-right: 10px;
  }

  .tab {
    font-size: 14px;
    padding: 6px 12px;
  }

  .date-filter-form input[type='date'] {
    font-size: 13px;
  }

  .date-filter-form button {
    font-size: 13px;
    padding: 6px 12px;
  }

  .info-tooltip {
    width: 160px;
  }
}

/* Mobile view for 500px to 700px */
@media (min-width: 500px) and (max-width: 700px) {
  .chart-container {
    padding: 0 15%; /* Adjusted padding for medium screens */
    padding-bottom: 55px;
  }

  .chart-header {
    font-size: 2rem;
  }

  .chart-description-container {
    margin-left: 30px;
  }

  .chart-description {
    font-size: 1.1em;
  }

  .data-source {
    font-size: 13px;
  }

  .checkbox-container {
    flex-direction: column;
  }

  .checkbox-container label {
    margin: 0 0 8px; /* Adjusted margin for better alignment */
  }

  .custom-legend li {
    margin-right: 15px;
  }

  .tab {
    font-size: 15px;
    padding: 8px 16px;
  }

  .date-filter-form input[type='date'] {
    font-size: 14px;
  }

  .date-filter-form button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .info-tooltip {
    width: 180px;
  }
}

/* Mobile view for 700px to 1000px */
@media (min-width: 700px) and (max-width: 1000px) {
  .chart-container {
    padding: 0 15%;
    padding-bottom: 50px;
  }

  .chart-header {
    font-size: 2.2rem;
  }

  .chart-description-container {
    margin-left: 20px;
  }

  .chart-description {
    font-size: 1.2em;
  }

  .data-source {
    font-size: 14px;
  }

  .checkbox-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .checkbox-container label {
    margin: 5px;
  }

  .custom-legend li {
    margin-right: 20px;
  }

  .tab {
    font-size: 16px;
    padding: 10px 20px;
  }

  .date-filter-form input[type='date'] {
    font-size: 15px;
  }

  .date-filter-form button {
    font-size: 15px;
    padding: 10px 20px;
  }

  .info-tooltip {
    width: 200px;
  }
}
