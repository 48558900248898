@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

/* Methodology.css */

.methodology-container {
  background: #f9f9f9;
  padding: 20px 20%;
  text-align: left;
  animation: fadeIn 1s ease-in-out;
}

.methodology-header {
  font-family: 'Manrope', sans-serif;
  color: rgb(129, 57, 57);
  margin-bottom: 20px;
  font-size: 2.5rem;
  animation: slideInFromLeft 1s ease-in-out;
}

.methodology-intro {
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out 0.5s forwards;
}

.methodology-intro p {
  font-family: 'Manrope', sans-serif;
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.methodology-section {
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}

.methodology-section h3 {
  font-family: 'Manrope', sans-serif;
  color: rgb(129, 57, 57);
  font-size: 1.8rem;
  margin-bottom: 10px;
  animation: fadeInUp 1s ease-in-out 0.3s forwards;
}

.methodology-section p {
  font-family: 'Manrope', sans-serif;
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  animation: fadeInUp 1s ease-in-out 0.6s forwards;
}

.methodology-section ul {
  font-family: 'Manrope', sans-serif;
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  list-style-type: none;
  padding-left: 0;
  animation: fadeInUp 1s ease-in-out 0.9s forwards;
}

.methodology-section ul li {
  margin-bottom: 10px;
}

.methodology-section ul li a {
  color: rgb(129, 57, 57);
  text-decoration: none;
}

.methodology-section ul li a:hover {
  text-decoration: underline;
}

.methodology-container a {
  color: rgb(129, 57, 57);
}

.methodology-container a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
