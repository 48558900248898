.map-container {
    background: #f9f9f9;
    width: 100%;
    padding: 0 20%; 
    text-align: center;
    animation: slideIn 0.5s ease-in-out;
    overflow-x: hidden; 
    padding-bottom: 60px;
}

.map-header {
    font-family: 'Manrope', sans-serif;
    color: rgb(129, 57, 57);
    margin-bottom: 20px; 
    font-size: 2.5rem;
}
  
.map-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; 
    margin-bottom: 20px;
}
  
.map-description {
    text-align: center;
    font-family: 'Manrope', sans-serif;
    font-size: 1.2em;
    color: #070707;
    font-weight: 520;
}

.info-icon-container {
    position: relative;
    display: inline-block;
    margin-right: 10px; 
    margin-top: 3px;
}
  
.info-tooltip {
    visibility: hidden;
    width: 200px;
    background-color: rgb(129, 57, 57);
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 125%; 
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-family: 'Manrope', sans-serif; 
}
  
.info-icon-container:hover .info-tooltip {
    visibility: visible;
    opacity: 1;
}
  
.info-tooltip::after {
    content: ' ';
    position: absolute;
    top: -10px; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(129, 57, 57) transparent transparent transparent;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
.checkbox-container label {
    margin: 5px;
    font-family: 'Manrope', sans-serif;
}
  
.checkbox-container input {
    margin: 0 10px; 
    cursor: pointer;
}

.button-toggle {
    padding: 10px 20px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    color: rgb(129, 57, 57);
    background-color: white;
    border: 2px solid rgb(129, 57, 57);
    border-radius: 5px;
    transition: all 0.3s ease;
    text-align: center;
    display: inline-block;
    margin-bottom: 40px;
}
  
.button-toggle.active {
    background-color: rgb(129, 57, 57);
    color: white;
}
  
.button-toggle.disabled {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
    border-color: #a0a0a0;
}

.toggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    margin-right: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
input:checked + .slider {
    background-color: #9b4242;
}
  
input:checked + .slider:before {
    transform: translateX(26px);
}
  
.toggleLabel {
    font-family: 'Manrope', sans-serif;
    font-size: 1.0em;
    color: #060000;
    font-weight: 600;
}
  
.label {
    font-family: 'Manrope', sans-serif;
    font-size: 1.0em;
    color: #060000;
    font-weight: 600;
}

.tooltip {
    position: absolute;
    background-color: #fff;
    border: 3px groove black;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 220px;
  }
  
  .tooltip-header {
    font-weight: bold;
  }
  
  .tooltip-body {
    margin-top: 5px;
  }

  .gradient-bar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.gradient-bar {
    width: 80%;
    height: 20px;
    display: flex;
    position: relative;
    margin-bottom: 5px;
}

.gradient-bar .tick-mark {
    position: absolute;
    left: 50%;
    top: 0;
    width: 10px;
    height: 100%;
    background-color: black;
    transform: translateX(-50%); /* Ensure it's centered */
  }

.gradient {
    height: 100%;
    flex: 1;
}

.gradient-labels {
    display: flex;
    justify-content: space-between;
    width: 80%;
    font-size: 0.9rem;
    color: #333;
    font-family: 'Manrope', sans-serif;
}
