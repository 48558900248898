/* Base Styles */
.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  color: #762a2a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
  vertical-align: top;
  width: 100%;
}

.header__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.center-align {
  text-align: center;
}

.header__intro {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  animation: zoom-in 1s ease-out;
  flex: 1;
  text-align: left; 
  margin-right: 100px;
  animation: fade-in 1s ease-out;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.pollexample {
  width: 85%;
  margin: 18px;
}

.header__intro h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.header__intro a {
  text-decoration: underline;
}

.header__polls {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 2.2rem;
  flex: 1;
  text-align: center;
  font-family: var(--font-family);
  margin-bottom: 1rem;
  margin-top: 1rem;
  animation: 1s ease-out;
  align-items: center;
}

.header__polls h3 {
  margin-left: 50px;
}

.header__polls p {
  font-size: 1.5rem;
}

.header__polls li {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.6rem 0;
  animation: bounce 1s ease-out;
}

.header__polls li a span {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.6rem 0;
  animation: bounce 1s ease-out;
  color: #762a2a;
  text-decoration: underline;
}

.header__polls span {
  font-weight: bold;
  color: #000000;
  font-size: 2rem;
}

.header__explanation {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: left;
}

.header__images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
}

.sims_header_image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
  box-shadow: none !important;
}

.header__images img {
  width: 100px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.redborder {
  border: #e04040 5px solid;
}

.blueborder {
  border: blue 5px solid;
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Extra-small devices (up to 400px) */
@media screen and (max-width: 400px) {
  .header__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .header__intro {
    font-size: 0.9rem;
    margin-right: 0;
    text-align: center;
  }

  .header__polls {
    font-size: 1.5rem;
  }

  .header__polls li {
    font-size: 1rem;
  }

  .header__polls span {
    font-size: 1.2rem;
  }

  .header__explanation {
    font-size: 0.8rem;
    text-align: center;
  }

  .header__images img {
    width: 70px;
  }
}

/* Extra-small devices (up to 500px) */
@media screen and (max-width: 500px) {
  .header__container {
    padding: 0 1rem;
    width: 100%;
  }

  .header__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .header__intro {
    font-size: 1rem;
    margin-right: 0;
    text-align: center;
  }

  .header__polls {
    font-size: 1.8rem;
  }

  .header__polls li {
    font-size: 1.2rem;
  }

  .header__polls span {
    font-size: 1.5rem;
  }

  .header__explanation {
    font-size: 0.9rem;
    text-align: center;
  }

  .header__images img {
    width: 80px;
  }
}

/* Small devices (500px to 1200px) */
@media screen and (min-width: 501px) and (max-width: 1200px) {
  .header__container {
    padding: 0 1.5rem;
  }

  .header__content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .header__intro {
    font-size: 1.25rem;
    text-align: center;
    margin-right: 0;
  }

  .header__polls {
    font-size: 2rem;
  }

  .header__polls li {
    font-size: 1.5rem;
  }

  .header__polls span {
    font-size: 2rem;
  }

  .header__explanation {
    font-size: 1rem;
    text-align: center;
  }

  .header__images img {
    width: 90px;
  }
}

/* Medium devices (1200px to 1400px) */
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .header__content {
    justify-content: center;
  }

  .header__intro {
    margin-left: 0;
    margin-right: 50px;
    font-size: 1.5rem;
    text-align: center;
  }

  .header__polls {
    font-size: 2.2rem;
  }

  .header__polls li {
    font-size: 1.7rem;
  }

  .header__polls span {
    font-size: 2.2rem;
  }

  .header__explanation {
    font-size: 1.1rem;
  }

  .header__images img {
    width: 95px;
  }
}

/* Large devices (1400px and larger) */
@media screen and (min-width: 1401px) {
  .header__content {
    justify-content: space-between;
  }

  .header__intro {
    font-size: 1.25rem;
  }

  .header__polls {
    font-size: 2.0rem;
  }

  .header__polls li {
    font-size: 1.5rem;
  }

  .header__polls span {
    font-size: 2.5rem;
  }

  .header__explanation {
    font-size: 1.2rem;
  }

  .header__images img {
    width: 100px;
  }
}

.info-icon-container {
  position: relative;
  display: inline-block;
  margin-left: 10px; /* Adjust as needed */
}

.info-icon {
  color: #762a2a; /* Adjust icon color */
  font-size: 1.2rem; /* Adjust icon size */
  vertical-align: middle;
}

.info-tooltip {
  visibility: hidden;
  width: 160px; /* Adjust tooltip width */
  background-color: #762a2a; /* Adjust tooltip background color */
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position tooltip below the icon */
  left: -40%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.9rem; /* Adjust tooltip font size */
}

.info-icon-container:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

.info-tooltip::after {
  content: ' ';
  position: absolute;
  top: 50px; /* Arrow pointing up */
  left: 10%;
  margin-left: -45px;
  border-width: 5px;
  border-style: solid;
  border-color: #762a2a transparent transparent transparent; /* Adjust arrow color */
}
