.home__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.home__content {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.home__description {
  flex: 1;
  padding: 1rem;
  text-align: center;
  color: white;
}

.home__description h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.home__description p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-top: 1.5rem;
}

.home__image1 {
  flex: 1;
  display: flex;
  justify-content: center;
  border: #704141;
}

.home__image2 {
  flex: 1;
  display: flex;
  justify-content: center;
  border: #704141;
}

.home__image1 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: 650px;
}

.home__image2 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: 600px;
}

.home__buttons {
  margin-top: 1rem;
}

.home__buttons button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #762a2a;
  transition: background-color 0.3s ease;
  flex: 0.6;
  width: 40%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #762a2a;
  border: 2px solid #762a2a;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.home__buttons button:hover {
  background-color: #5e1e1e;
}


@media screen and (max-width: 700px) {
  .home__container {
    padding: 2rem;
  }

  .home__content {
    flex-direction: column;
    margin: 1rem 0;
  }

  .home__description,
  .home__image {
    flex: none;
    width: 100%;
    margin-bottom: 1rem;
  }

  .home__description {
    order: 2;
  }

  .home__image {
    order: 1;
  }

  .slide-left,
  .slide-right {
    animation: none;
  }
}

@media screen and (max-width: 550px) {
  .home__buttons button {
    margin: 0.5rem 0;
    width: 100%;
  }
}

/* Extra-small devices (up to 400px) */
@media screen and (max-width: 400px) {
  .home__container {
    padding: 1rem;
  }

  .home__content {
    flex-direction: column;
    margin: 1rem 0;
  }

  .home__description,
  .home__image {
    flex: none;
    width: 100%;
    margin-bottom: 1rem;
  }

  .home__description h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .home__description p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .home__image1 img,
  .home__image2 img {
    width: 100%;
  }

  .home__buttons button {
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}

/* Extra-small devices (up to 500px) */
@media screen and (max-width: 500px) {
  .home__container {
    padding: 1.5rem;
  }

  .home__content {
    flex-direction: column;
    margin: 1.5rem 0;
  }

  .home__description,
  .home__image {
    flex: none;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .home__description h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .home__description p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .home__image1 img,
  .home__image2 img {
    width: 100%;
  }

  .home__buttons button {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/* Small devices (500px to 1200px) */
@media screen and (min-width: 501px) and (max-width: 1200px) {
  .home__container {
    padding: 2rem;
  }

  .home__content {
    flex-direction: column;
    margin: 2rem 0;
  }

  .home__description,
  .home__image {
    flex: none;
    width: 100%;
    margin-bottom: 2rem;
  }

  .home__description h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .home__description p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .home__image1 img,
  .home__image2 img {
    width: 100%;
  }

  .home__buttons button {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

/* Medium devices (1200px to 1400px) */
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .home__container {
    padding: 2.5rem;
  }

  .home__content {
    flex-direction: row;
    margin: 2.5rem 0;
  }

  .home__description,
  .home__image {
    flex: 1;
    width: 50%;
    margin-bottom: 0;
  }

  .home__description h2 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }

  .home__description p {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }

  .home__image1 img,
  .home__image2 img {
    width: 90%;
  }

  .home__buttons button {
    width: 80%;
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

/* Large devices (1400px and larger) */
@media screen and (min-width: 1401px) {
  .home__container {
    padding: 3rem;
  }

  .home__content {
    flex-direction: row;
    margin: 3rem 0;
  }

  .home__description,
  .home__image {
    flex: 1;
    width: 50%;
    margin-bottom: 0;
  }

  .home__description h2 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .home__description p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .home__image1 img,
  .home__image2 img {
    width: 80%;
  }

  .home__buttons button {
    width: 60%;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-left {
  from {
    transform: translateX(40px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-40px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slide-up 0.5s ease-out;
}

.slide-left {
  animation: slide-left 0.5s ease-out;
}

.slide-right {
  animation: slide-right 0.5s ease-out;
}
