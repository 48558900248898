@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

body, html {
  font-family: 'Manrope', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.media-container {
  background: #f4f4f4;
  padding: 40px 10%;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.media-header {
  color: #762a2a;
  margin-bottom: 40px;
  font-size: 2.8rem;
  font-weight: 700;
  animation: slideInFromLeft 1.5s ease-in-out;
}

.recent-publications,
.publication-timeline {
  margin-bottom: 60px;
}

.section-title {
  color: #762a2a;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  border-bottom: 2px solid #762a2a;
  display: inline-block;
  padding-bottom: 10px;
}

.media-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.media-item {
  flex: 1 1 calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  opacity: 0; /* Initially hidden */
}

.media-item img {
  width: 100%;
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* Maintain aspect ratio while covering the area */
  border-bottom: 1px solid #ddd;
}

.media-item a {
  color: #762a2a;
  text-decoration: none;
  text-align: center;
  font-size: 1.1rem;
  padding: 15px;
  display: block;
  transition: color 0.3s, transform 0.3s;
}

.media-item span {
  color: #762a2a;
  text-decoration: none;
  text-align: center;
  font-size: 1.1rem;
  padding: 15px;
  display: block;
  transition: color 0.3s, transform 0.3s;
}

.media-item a:hover {
  color: #762a2a;
  transform: scale(1.05);
}

.media-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Timeline Styles */
.timeline {
  margin-top: 40px;
  padding-left: 40px;
  border-left: 3px solid #ddd;
  position: relative;
  animation: fadeIn 1s ease-in-out forwards;
}


/* Removed animation for timeline items */
.timeline-year {
  font-weight: bold;
  margin-right: 20px;
  font-size: 1.2rem;
  color: #333;
  position: absolute;
  left: -60px;
}

.timeline-content a {
  font-size: 1.1rem;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.timeline-content span {
  font-size: 1.1rem;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.timeline-content a:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

/* Keyframe animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Line-by-line animation */
@keyframes lineByLine {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  padding-left: 20px;
  opacity: 0; /* Initially hidden */
  animation: lineByLine 0.8s ease-out forwards;
}

.timeline-item.animate {
  animation: lineByLine 0.8s ease-out forwards;
}



/* Adjusted animations for media items */
.media-item.animate-0 {
  animation: slideUp 0.6s ease-out forwards;
  animation-delay: 0s;
}

.media-item.animate-1 {
  animation: slideUp 0.6s ease-out forwards;
  animation-delay: 0.2s;
}

.media-item.animate-2 {
  animation: slideUp 0.6s ease-out forwards;
  animation-delay: 0.4s;
}

.media-item.animate-3 {
  animation: slideUp 0.6s ease-out forwards;
  animation-delay: 0.6s;
}

.year {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 700;
  color: #762a2a;
  margin-bottom: 20px;
}

/* Mobile view for 400px to 499px */
@media (max-width: 499px) {
  .media-container {
    padding: 20px 5%;
  }

  .media-header {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .media-item {
    flex: 1 1 calc(100% - 20px);
  }

  .media-item img {
    height: 150px; /* Adjust height for smaller screens */
  }

  .media-item a {
    font-size: 0.9rem;
    padding: 10px;
  }

  .timeline-year {
    font-size: 1rem;
  }

  .timeline-content a {
    font-size: 0.9rem;
  }
}

/* Mobile view for 500px to 700px */
@media (min-width: 500px) and (max-width: 700px) {
  .media-container {
    padding: 30px 7%;
  }

  .media-header {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .media-item {
    flex: 1 1 calc(50% - 20px);
  }

  .media-item img {
    height: 175px; /* Adjust height for medium screens */
  }

  .media-item a {
    font-size: 1rem;
    padding: 12px;
  }

  .timeline-year {
    font-size: 1.1rem;
  }

  .timeline-content a {
    font-size: 1rem;
  }
}

/* Mobile view for 700px to 1200px */
@media (min-width: 700px) and (max-width: 1200px) {
  .media-container {
    padding: 35px 8%;
  }

  .media-header {
    font-size: 2.6rem;
    margin-bottom: 35px;
  }

  .section-title {
    font-size: 1.9rem;
  }

  .media-item {
    flex: 1 1 calc(33.33% - 20px);
  }

  .media-item img {
    height: 200px; /* Maintain height for larger screens */
  }

  .media-item a {
    font-size: 1.1rem;
    padding: 15px;
  }

  .timeline-year {
    font-size: 1.2rem;
  }

  .timeline-content a {
    font-size: 1.1rem;
  }
}

/* Mobile view for 1201px to 1400px */
@media (min-width: 1201px) and (max-width: 1400px) {
  .media-container {
    padding: 40px 10%;
  }

  .media-header {
    font-size: 2.8rem;
    margin-bottom: 40px;
  }

  .section-title {
    font-size: 2rem;
  }

  .media-item {
    flex: 1 1 calc(25% - 20px);
  }

  .media-item img {
    height: 200px; /* Maintain height for large screens */
  }

  .media-item a {
    font-size: 1.1rem;
    padding: 15px;
  }

  .timeline-year {
    font-size: 1.2rem;
  }

  .timeline-content a {
    font-size: 1.1rem;
  }
}