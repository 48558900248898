.people-container {
  background: #f9f9f9;
  color: #7a1818;
}

.people-container h1 {
  text-align: center;
  font-size: 2.5em;
  font-family: var(--font-family);
  font-weight: 800;
  line-height: 65px;
  letter-spacing: -0.04em;
}


.people-container h1{
  font-size: 2em;
  text-align: center;
  font-family: var(--font-family);
  margin-bottom: 50px;
}

.people-container h2 {
  font-size: 1.5em;
  text-align: center;
  font-family: var(--font-family);
  margin-bottom: 70px;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  font-size: 20px;
}

@keyframes scaleUp {
  from {
      transform: scale(0.8);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

.principal-investigator-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 40px;
  animation: scaleUp 1s ease-in-out;
  width: 390px;
  margin-left: 40px;
  margin-right: 40px;
}

.researcher-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
  animation: scaleUp 1s ease-in-out;
  width: 390px;
}

.title-animation {
  animation: scaleUp 1s ease-in-out;

}

.team-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.team-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-details {
  padding: 10px;
  text-align: center;
}

.principal-investigator-details h2, .researcher-details h2 {
  margin: 0px 0;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 30px;
}

.principal-investigator-details p, .researcher-details p {
  margin: 5px 0;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.principal-investigator-details a, .researcher-details a {
  display: block;
  margin-top: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #7a1818;
  text-decoration: none;
}

.principal-investigator-details a:hover, .researcher-details a:hover {
  text-decoration: underline;
}
