.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__navbar-links h2 {
    display: flex;
    justify-content: flex-start;
    color: #762a2a;
    font-family: var(--font-family);
    font-weight: 1000;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 0rem 0rem 0; /* Increased margin-right to 0.5rem */
    cursor:pointer;
}

.gpt3__navbar-links p {
    color: #762a2a;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0.1 0rem;
    cursor: pointer;
}

.by {
    text-transform: none !important;
    margin-left: 0.5rem; /* Reduced margin-left */
    margin-right: 0.2rem; /* Reduced margin-right */
    cursor:pointer;
}

.gpt3__navbar-links_logo {
    margin-right: 8rem;
}

.gpt3__navbar-links_logo img {
    width: 72.56px;
    cursor: pointer;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-left: 2rem; /* Increased margin-left */
}

.gpt3__navbar-links_container p,
.gpt3__navbar-menu_container p {
    color: #762a2a;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-links_container p a.active,
.gpt3__navbar-menu_container p a.active {
    text-decoration: underline;
    text-underline-offset: 4px;
    font-weight: 700;
    color: #762a2a; /* Optional: Change color to indicate active link */
}

.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

.disabled-link {
    color: gray;
    pointer-events: none;
    cursor: default;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
